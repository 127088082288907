.gpt3__header{
    display: flex;
}

.gpt3__header-content {
    flex:1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.gpt3__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}
.gpt3__header-content p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gpt3__header-content__input{
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
}

.gpt3__header-content__input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    background:var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color:#fff;
}

.gpt3__header-content__input button{
    flex:0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    background:#ff4820;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
}
.gpt3__header-content__people{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 2rem;
}
.gpt3__header-content__people img{
    width: 181.79px;
    height: 38px;
}
.gpt3__header-content__people p{
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #ffffff;
    text-align: center;
}
.gpt3__header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gpt3__header-image img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px){
    .gpt3__header{
        flex-direction: column;
    }
    .gpt3__header-content{
        margin: 0 0 3rem;
    }
}
@media screen and (max-width: 650px){
    .gpt3__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .gpt3__header p{
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-content__people{
        flex-direction: column;
    }
    .gpt3__header-content__people{
        margin: 0;
    }
    .gpt3__header-content__input input,
.gpt3__header-content__input button{
    font-size: 16px;
    line-height: 24px;
}
}
@media screen and (max-width: 490px){
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .gpt3__header p{
        font-size: 14px;
        list-style: 24px;
    }
    .gpt3__header-content__input input,
.gpt3__header-content__input button{
    font-size: 12px;
    line-height: 16px;
}
}